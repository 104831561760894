var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', {
    staticClass: "d-flex",
    staticStyle: {
      "position": "relative"
    }
  }, [_c('v-fade-transition', {
    attrs: {
      "leave-absolute": ""
    }
  }, [_c('v-file-btn', _vm._b({
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.file,
      expression: "!file"
    }],
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.file,
      callback: function callback($$v) {
        _vm.file = $$v;
      },
      expression: "file"
    }
  }, 'v-file-btn', Object.assign({}, _vm.$attrs, {
    disabled: _vm.readonly
  }), false), [_vm._t("default"), _vm._v(" " + _vm._s(_vm.title) + " 첨부 ")], 2)], 1), _c('v-fade-transition', {
    attrs: {
      "leave-absolute": ""
    }
  }, [_c('image-popup', _vm._b({
    attrs: {
      "hasCustomActivator": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var _vm$file;

        var attrs = _ref.attrs,
            on = _ref.on;
        return [_c('v-btn', _vm._g(_vm._b({
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.file,
            expression: "file"
          }],
          staticStyle: {
            "position": "relative",
            "flex": "0 0 0"
          }
        }, 'v-btn', Object.assign({
          title: _vm.title
        }, attrs, _vm.$attrs), false), on), [_c('v-row', {
          staticStyle: {
            "position": "absolute",
            "width": "100%"
          }
        }, [_c('v-col', {
          staticClass: "text-truncate"
        }, [_vm._v(" " + _vm._s((_vm$file = _vm.file) === null || _vm$file === void 0 ? void 0 : _vm$file.name) + " ")]), !_vm.readonly ? _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          },
          on: {
            "!click": function click($event) {
              $event.preventDefault();
              $event.stopPropagation();
              _vm.file = null;
            }
          }
        }, [_vm._v("mdi-close-circle")])], 1) : _vm._e()], 1)], 1)];
      }
    }])
  }, 'image-popup', {
    src: _vm.src,
    title: _vm.title
  }, false))], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }