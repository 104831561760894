<template>
    <v-btn v-bind="$attrs" @click="click">
        <slot></slot>
        <v-file-input v-model="file" ref="fileInput" v-bind="$attrs" class="d-none" @change="input"></v-file-input>
    </v-btn>
</template>
<script>
export default {
    data(){
        return {
            file: null
        }
    },
    methods: {
        input(file){
            this.file = null;
            this.$emit("change", file);
            this.$emit("input", file);
        },
        click(){
            this.$refs.fileInput.$el.getElementsByTagName("input")[0].click();
        }
    }
}
</script>