<template>
    <v-sheet>
        <v-text-field v-model="postcode" @click="search" label="우편번호" readonly :class="$attrs.className" v-bind="{ ...$attrs, rules }" hide-details>
            <template #append-outer> <v-btn v-bind="btnAttrs" :class="btnAttrs?.className" @click="search">검색</v-btn> </template>
        </v-text-field>
        <v-text-field v-model="address1" @click="search" label="기본주소" readonly :class="$attrs.className" v-bind="{ ...$attrs, rules }" hide-details />
        <v-text-field v-model="address2" label="상세주소" :class="$attrs.className" v-bind="{ ...$attrs, rules }" @keydown.enter="$emit('emit')" />
        <daum-postcode ref="daum-postcode" @change="processAddressData" />
    </v-sheet>
</template>

<script>
import DaumPostcode from "@/components/plugins/daum/daum-postcode.vue";
export default {
    components: {
        DaumPostcode,
    },
    props: {
        value: { type: Object, default: () => ({}) }, // user
        btnAttrs: { type: Object, default: () => ({}) }, // user
        noRules: { type: Boolean, default: false },
    },
    data: () => ({
        postcode: null,
        address1: null,
        address2: null,
    }),
    computed: {
        rules() {
            if (this.noRules) return;
            return [() => (!!this.postcode && !!this.address1 && !!this.address2) || "주소를 입력해주세요"];
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
        postcode() {
            this.emit();
        },
        address1() {
            this.emit();
        },
        address2() {
            this.emit();
        },
    },
    methods: {
        sync() {
            this.postcode = this.value?.postcode || null;
            this.address1 = this.value?.address1 || null;
            this.address2 = this.value?.address2 || null;
        },
        emit() {
            const { postcode, address1, address2 } = this;
            this.$emit("input", { ...this.value, postcode, address1, address2 });
        },
        search() {
            this.$refs["daum-postcode"]?.open?.();
        },
        processAddressData({ postcode, address }) {
            this.postcode = postcode;
            this.address1 = address;
        },
    },
};
</script>

