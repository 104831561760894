<template>
    <v-menu ref="menu" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="290px" max-width="290px" v-bind="$attrs">
        <template v-slot:activator="{ on }">
            <v-text-field :value="formatData" append-icon="event" readonly v-bind="$attrs" v-on="on" @click:append-outer="on.click" @click:clear="date = null" />
        </template>
        <v-date-picker v-model="date" v-bind="{ weekdayFormat, monthFormat, titleDateFormat, headerDateFormat }" no-title scrollable type="date" color="secondary" locale="en-US" @input="$refs.menu.save(date)" />
    </v-menu>
</template>

<script>
export default {
    props: {
        value: { type: String, default: undefined },
    },
    data() {
        return {
            shows: false,
            date: this.$props.value,
        };
    },
    watch: {
        value() {
            this.date = this.value;
        },
        date() {
            this.$emit("input", this.date);
        },
    },
    computed: {
        formatData() {
            return this.date?.substr(0, 10);
        },
    },
    methods: {
        weekdayFormat(date) {
            const daysOfWeek = ["일", "월", "화", "수", "목", "금", "토"];
            let i = new Date(date).getDay(date);
            return daysOfWeek[i];
        },
        monthFormat(date) {
            const monthName = ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"];
            let i = new Date(date).getMonth(date);
            return monthName[i];
        },
        titleDateFormat(date) {
            return this.monthFormat(date);
        },
        headerDateFormat(date) {
            const monthName = ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"];
            let i = new Date(date).getMonth(date);
            const year = new Date(date).getFullYear(date);
            const month = monthName[i];
            return `${year}년 ${month}`;
        },
    },
};
</script>

<style lang="scss" scoped></style>
