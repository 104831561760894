var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "daum-postcode",
    class: {
      active: _vm.show
    }
  }, [_c('div', {
    ref: "popup",
    staticClass: "popup-layer"
  }, [_c('img', {
    staticClass: "close",
    attrs: {
      "src": "//t1.daumcdn.net/localimg/localimages/07/postcode/320/close.png",
      "alt": "닫기 버튼"
    },
    on: {
      "click": _vm.close
    }
  })])]);
}
var staticRenderFns = []

export { render, staticRenderFns }