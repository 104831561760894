var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "85vh",
      "width": "85%",
      "disabled": !_vm.src
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var attrs = _ref.attrs,
            on = _ref.on;
        return [_vm.hasCustomActivator ? _vm._t("activator", null, null, {
          attrs: attrs,
          on: on
        }) : _c('v-avatar', _vm._g(_vm._b({
          style: !_vm.src ? 'cursor:initial' : ''
        }, 'v-avatar', Object.assign({}, attrs, _vm.avatarAttrs), false), on), [_c('v-img', _vm._b({
          scopedSlots: _vm._u([{
            key: "placeholder",
            fn: function fn() {
              return [_c('v-icon', {
                attrs: {
                  "dark": ""
                }
              }, [_vm._v("mdi-image")])];
            },
            proxy: true
          }], null, true)
        }, 'v-img', {
          src: _vm.src
        }, false))], 1)];
      }
    }], null, true),
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', {
    attrs: {
      "dark": ""
    }
  }, [_vm.src ? _c('v-img', _vm._b({
    attrs: {
      "eager": ""
    }
  }, 'v-img', {
    src: _vm.src
  }, false), [_vm.title ? _c('v-card-title', {
    staticStyle: {
      "background-color": "rgba(0, 0, 0, 0.3) !important"
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " "), _c('v-spacer'), _c('v-icon', {
    on: {
      "click": function click($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v("mdi-close")])], 1) : _c('v-btn', {
    staticStyle: {
      "background-color": "rgba(0, 0, 0, 0.3)"
    },
    attrs: {
      "absolute": "",
      "right": "",
      "top": "",
      "icon": "",
      "tile": "",
      "dark": "",
      "x-large": ""
    },
    on: {
      "click": function click($event) {
        _vm.dialog = false;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "4rem"
    }
  }, [_vm._v("mdi-close")])], 1)], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }