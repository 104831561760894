<template>
    <div class="daum-postcode" :class="{ active: show }">
        <div ref="popup" class="popup-layer">
            <img src="//t1.daumcdn.net/localimg/localimages/07/postcode/320/close.png" @click="close" class="close"  alt="닫기 버튼">
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: { type: Object, default: () => {
            return {
                postcode: "",
                address: ""
            }
        }}
    },
    data: function(){
        return {
            show: false,
            data: {}
        };
    },
    methods: {
        open(){
            this.show = true;
        },
        close(){
            this.show = false;
        },
        load(){
            this.init();
        },
        init(){
            var component = this;
            new daum.Postcode({
                oncomplete: function(data) {
                    //전체 주소에서 연결 번지 및 ()로 묶여 있는 부가정보를 제거하고자 할 경우,
                    //아래와 같은 정규식을 사용해도 된다. 정규식은 개발자의 목적에 맞게 수정해서 사용 가능하다.
                    //var addr = data.address.replace(/(\s|^)\(.+\)$|\S+~\S+/g, '');
                    //document.getElementById('addr').value = addr;
                    component.data.postcode = data.zonecode;
                    component.data.address = data.roadAddress || data.jibunAddress;
                    component.$emit('input', component.data);
                    component.$emit('change', component.data);
                    component.$set(component.$data, "show", false);
                },
                width: '100%',
                height: '100%',
                maxSuggestItems: 5
            }).embed(this.$refs.popup);
        }
    },
    watch: {
        value(){
            this.data = this.value;
        },
        show(){
            if(this.show) this.init();
        }
    }
}
</script>

<style>
.daum-postcode { display: none; position: fixed; width: 100%; height: 100%; top: 0; left: 0; bottom: 0; right: 0; z-index: 99999; background: rgba(0,0,0,0.34) }
.daum-postcode.active { display: inline-block; }
.daum-postcode .popup-layer { display: inline-block; position: absolute; width: 400px; height: 520px; left: 0; right: 0; top: 0; bottom: 0; margin: auto; background: #fff; border: 2px solid #333; }
.daum-postcode .close { cursor:pointer; position:absolute; right:-3px; top:-3px; z-index:1 }

@media (max-width: 768px){
    .daum-postcode .popup-layer { position: absolute; width: 100%; height: 100%; left: 0; right: 0; top: 0 !important; bottom: 0; margin: auto; background: #fff; }
}
</style>
