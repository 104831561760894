export const initSender = () => ({
    name: "",
    phone: "",
    address: "경기도 안성시 대덕면 모산로 394 신진유통 물류센터",
});

export const initReceiver = () => ({
    name: "",
    phone: "",
    postcode: "",
    address1: "",
    address2: "",
});
