<template>
    <v-sheet class="d-flex" style="position: relative">
        <v-fade-transition leave-absolute>
            <v-file-btn v-show="!file" v-model="file" v-bind="{ ...$attrs, disabled: readonly }" @input="emit"> <slot /> {{ title }} 첨부 </v-file-btn>
        </v-fade-transition>
        <v-fade-transition leave-absolute>
            <image-popup hasCustomActivator v-bind="{ src, title }">
                <template #activator="{ attrs, on }">
                    <v-btn v-show="file" v-bind="{ title, ...attrs, ...$attrs }" v-on="on" style="position: relative; flex: 0 0 0">
                        <v-row style="position: absolute; width: 100%">
                            <v-col class="text-truncate">
                                {{ file?.name }}
                            </v-col>
                            <v-col cols="auto" v-if="!readonly">
                                <v-icon small @click.prevent.stop.capture="file = null">mdi-close-circle</v-icon>
                            </v-col>
                        </v-row>
                    </v-btn>
                </template>
            </image-popup>
        </v-fade-transition>
    </v-sheet>
</template>

<script>
import VFileBtn from "@/components/plugins/vuetify/v-file-btn.vue";
import ImagePopup from "./image-popup.vue";
export default {
    components: {
        VFileBtn,
        ImagePopup,
    },
    props: {
        value: { type: [File, Object], default: null },
        title: { type: String, default: "" },
        readonly: { type: Boolean, default: false },
    },
    data: () => ({
        file: null,
    }),
    computed: {
        src() {
            return this.file ? this.file?.src || URL.createObjectURL(this.file) : null;
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.file = this.value;
        },
        emit() {
            this.$emit("input", this.file);
        },
    },
};
</script>

<style>
</style>